(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-wager/assets/utils/fetch-additional-draw-info.js') >= 0) return;  svs.modules.push('/components/payment/service-wager/assets/utils/fetch-additional-draw-info.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  productIds,
  getSpecificDrawName
} = svs.utils.products;
const {
  jupiterFetch
} = svs.core;
const {
  CurrencyAsJupiterString
} = svs.utils.format;
const jackpotCache = {};
const drawInfoCache = {};
const jackpotProducts = [productIds.STRYKTIPSET, productIds.EUROPATIPSET, productIds.FULLTRAFF, productIds.MALTIPSET];
const getCacheKey = _ref => {
  let {
    productId,
    drawNumber
  } = _ref;
  return "".concat(productId, "_").concat(drawNumber);
};
const parseDraw = draw => {
  var _draw$fund, _draw$fund2;
  return _objectSpread(_objectSpread({}, draw), {}, {
    fund: _objectSpread(_objectSpread({}, draw.fund), {}, {
      rolloverIn: draw === null || draw === void 0 || (_draw$fund = draw.fund) === null || _draw$fund === void 0 ? void 0 : _draw$fund.rolloverIn.replace(',', '.'),
      extraMoney: draw === null || draw === void 0 || (_draw$fund2 = draw.fund) === null || _draw$fund2 === void 0 ? void 0 : _draw$fund2.extraMoney.replace(',', '.')
    })
  });
};

const fetchInfoForProducts = async (products, jackpots) => {
  const drawsPaths = [];
  for (const {
    productId,
    drawNumber
  } of products) {
    const productDrawName = getSpecificDrawName(productId);
    const drawPath = ["/draw/1/".concat(productDrawName, "/draws/").concat(drawNumber, "?include="), encodeURIComponent(['draw.fund.rolloverIn', 'draw.fund.extraMoney', "draw.regOpenTime"].join(','))].join('');
    drawsPaths.push(drawPath);
  }
  const jackpotPaths = [];
  for (const {
    productId
  } of jackpots) {
    const productDrawName = getSpecificDrawName(productId);
    const jackpotPath = ["/draw/1/jackpot/".concat(productDrawName, "?include="), encodeURIComponent(['jackpot.jackpots.jackpotAmount', 'jackpot.guaranteedJackpots.jackpotAmount'].join(','))].join('');
    jackpotPaths.push(jackpotPath);
  }
  const mfSeparator = encodeURIComponent('|');
  const response = await jupiterFetch.fetch("/multifetch?serial=false&urls=".concat([...drawsPaths, ...jackpotPaths].join(mfSeparator)), {
    method: 'GET',
    headers: {
      accept: 'application/json'
    }
  });
  const data = await response.json();
  if (!response.ok) {
    var _data$error;
    throw new Error((data === null || data === void 0 || (_data$error = data.error) === null || _data$error === void 0 ? void 0 : _data$error.message) || 'Error fetching product info');
  }
  const drawResponses = data.responses.slice(0, drawsPaths.length);
  const jackpotResponses = jackpotPaths.length ? data.responses.slice(-jackpotPaths.length) : [];
  const drawData = {};
  const jackpotData = {};
  for (let i = 0; i < products.length; i++) {
    const key = getCacheKey(products[i]);
    const response = drawResponses[i];
    drawData[key] = response.error ? {} : parseDraw(response.draw);
    drawInfoCache[key] = drawData[key];
  }
  for (let i = 0; i < jackpots.length; i++) {
    const {
      productId
    } = jackpots[i];
    const response = jackpotResponses[i];
    jackpotData[productId] = [];
    if (!response.error) {
      const jp = response.jackpot.jackpots.map(_ref2 => {
        let {
          jackpotAmount
        } = _ref2;
        return CurrencyAsJupiterString(jackpotAmount).replace(',', '.');
      });
      const gjp = response.jackpot.guaranteedJackpots.map(_ref3 => {
        let {
          jackpotAmount
        } = _ref3;
        return CurrencyAsJupiterString(jackpotAmount).replace(',', '.');
      });
      jackpotData[productId] = [...jp, ...gjp];
    }
    jackpotCache[productId] = jackpotData[productId];
  }
  return {
    draws: drawData,
    jackpots: jackpotData
  };
};

const fetchAdditionalDrawInfo = async products => {
  const productsToFetch = products.filter(product => {
    const key = getCacheKey(product);
    return !drawInfoCache[key];
  });
  const jackpotsToFetch = products.filter(_ref4 => {
    let {
      productId
    } = _ref4;
    return !jackpotCache[productId] && jackpotProducts.includes(productId);
  });
  if (productsToFetch.length || jackpotsToFetch.length) {
    await fetchInfoForProducts(productsToFetch, jackpotsToFetch);
  }
  const retJackpot = {};
  const retDraw = {};
  for (const product of products) {
    const {
      productId
    } = product;
    if (jackpotCache[productId]) {
      retJackpot[productId] = jackpotCache[productId];
    }
    const key = getCacheKey(product);
    retDraw[key] = drawInfoCache[key];
  }
  return {
    jackpots: retJackpot,
    draws: retDraw
  };
};
const emptyObject = obj => {
  const keys = Object.keys(obj);
  for (const key of keys) {
    delete obj[key];
  }
};
const clearCache = () => {
  emptyObject(jackpotCache);
  emptyObject(drawInfoCache);
};
fetchAdditionalDrawInfo.clearCache = clearCache;
setGlobal('svs.components.payment.serviceWager.utils.fetchAdditionalDrawInfo', fetchAdditionalDrawInfo);

 })(window);