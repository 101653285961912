(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-wager/assets/wager-service.js') >= 0) return;  svs.modules.push('/components/payment/service-wager/assets/wager-service.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  PaymentService
} = svs.components.payment.servicePayment;
const {
  isWager
} = svs.components.payment.createWager.utils;
const {
  PaymentError
} = svs.components.payment.common.error.PaymentError;
const {
  services,
  utils
} = svs.components.payment.serviceWager;
const {
  account_balance: accountBalance
} = svs.components;
const {
  userSession
} = svs.core;

const createPaymentDTO = (wager, wagerResponse) => {
  const isMarketplaceWager = wager.getIsMarketplaceWager();
  let accountBalance = null;
  if (!isMarketplaceWager) {
    accountBalance = wagerResponse.accountBalance;
  }
  return Object.freeze({
    get cost() {
      return parseFloat(wagerResponse.wagerAmounts.totalAmount.replace(/,/g, '.'));
    },
    get balance() {
      return accountBalance;
    },
    get transactionId() {
      return wagerResponse.wagerClientSerial;
    }
  });
};

const assertInstanceOfWager = wager => {
  if (!isWager(wager)) {
    throw new PaymentError({
      clientCode: PaymentError.ClientErrorCodes.NOT_WAGER
    });
  }
};

var _accountBalance = new WeakMap();
var _totalCost = new WeakMap();
class WagerService extends PaymentService {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _accountBalance, null);
    _classPrivateFieldInitSpec(this, _totalCost, null);
  }
  assertPayable() {
    for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
      items[_key] = arguments[_key];
    }
    for (const wager of items) {
      super.assertPayable(wager);
      assertInstanceOfWager(wager);
    }
  }
  async processRequest(wager) {
    var _paymentDTO$balance;
    const isMarketplaceWager = wager.getIsMarketplaceWager();
    const isRacingWager = wager.getIsRacingWager();
    let postWagerFunc = services.postWager;
    if (isMarketplaceWager) {
      postWagerFunc = services.postMarketplaceWager;
    } else if (isRacingWager) {
      postWagerFunc = services.postRacingWager;
    }
    const paymentDTO = await postWagerFunc(wager);
    _classPrivateFieldSet(_accountBalance, this, (_paymentDTO$balance = paymentDTO.balance) !== null && _paymentDTO$balance !== void 0 ? _paymentDTO$balance : _classPrivateFieldGet(_accountBalance, this));
    _classPrivateFieldSet(_totalCost, this, _classPrivateFieldGet(_totalCost, this) + paymentDTO.cost);
    return createPaymentDTO(wager, paymentDTO);
  }
  onPaymentDone(paidWagersData) {
    const lastPaidItem = paidWagersData.slice(-1).pop();
    const hasNewBalance = Boolean(lastPaidItem);

    if (hasNewBalance && userSession.hasRole(userSession.roles.PLAYER)) {
      accountBalance.set(lastPaidItem.dto.balance);
    }
  }
  trackPayments(wagersData, userContext) {
    utils.trackWagers(wagersData, userContext);
  }
}
setGlobal('svs.components.payment.serviceWager.WagerService', WagerService);

 })(window);