(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-wager/assets/services/post-wager.js') >= 0) return;  svs.modules.push('/components/payment/service-wager/assets/services/post-wager.js');
'use strict';

const {
  RequestPaymentError
} = svs.components.payment.common.error;
const {
  jupiterFetch
} = svs.core;

const postWager = async wager => {
  try {
    const response = await jupiterFetch.fetch("/wager/10/wagers", {
      method: 'POST',
      body: JSON.stringify(wager),
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    });
    const data = await response.json();
    if (!response.ok) {
      var _data$error, _data$error2;
      throw new RequestPaymentError({
        clientCode: RequestPaymentError.ClientErrorCodes.PAYMENT_REQUEST_FAILURE,
        code: (_data$error = data.error) === null || _data$error === void 0 ? void 0 : _data$error.code,
        extraData: {
          message: (_data$error2 = data.error) === null || _data$error2 === void 0 ? void 0 : _data$error2.message
        }
      });
    }
    return data;
  } catch (error) {
    switch (error === null || error === void 0 ? void 0 : error.name) {
      case 'TypeError':
        throw new RequestPaymentError({
          clientCode: RequestPaymentError.ClientErrorCodes.PAYMENT_FETCH_FAILURE,
          code: error === null || error === void 0 ? void 0 : error.code,
          extraData: {
            message: error === null || error === void 0 ? void 0 : error.message
          }
        });
      default:
        throw error;
    }
  }
};
setGlobal('svs.components.payment.serviceWager.services.postWager', postWager);

 })(window);