(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-wager/assets/utils/track-wagers.js') >= 0) return;  svs.modules.push('/components/payment/service-wager/assets/utils/track-wagers.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  fetchAdditionalDrawInfo
} = svs.components.payment.serviceWager.utils;
const {
  logger
} = svs.components.payment.common;
const {
  transaction: Transaction,
  transactionItem: TransactionItem
} = svs.components.analytics;
const {
  analytics
} = svs.components;
const {
  products
} = svs.utils;
const {
  convertRoleToTypeOfShare
} = svs.components.marketplaceData.constants;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;


const addonProducts = [products.productIds.SPORTKRYSS];
const addonProductName = {
  [products.productIds.SPORTKRYSS]: 'Sportkrysset'
};
const addonProductId = {
  [products.productIds.SPORTKRYSS]: 'SPORTJOKER'
};
const relatedAddonProductName = {
  [products.productIds.STRYKTIPSET]: 'Fullträff Stryktipset',
  [products.productIds.T8STRYK]: 'Fullträff Topptipset'
};
const ADDITIONAL_PRODUCTS = [products.productIds.STRYKTIPSET, products.productIds.EUROPATIPSET, products.productIds.MATCHEN, products.productIds.BOMBEN, products.productIds.MALTIPSET, products.productIds.T8STRYK, products.productIds.T8EXTRA, products.productIds.T8, products.productIds.POWERPLAY, products.productIds.CHALLENGE, products.productIds.HVS, products.productIds.FULLTRAFF];
const fetchDrawInfo = async products => {
  try {
    const drawInfo = await fetchAdditionalDrawInfo(products);
    return drawInfo;
  } catch (_unused) {
    const productLog = products.map(_ref => {
      let {
        productId,
        drawNumber
      } = _ref;
      return "".concat(productId, ":").concat(drawNumber);
    }).join(',');
    logger.info("Unable to provide extra transaction tracking info for ".concat(productLog));
    return null;
  }
};

const createTransaction = (payableData, drawInfo, userContext, typeOfShares) => {
  const compHandler = competitionHandler();
  const productBet = payableData.payable.bets.find(bet => !addonProducts.includes(bet.productId));
  const totalPrice = payableData.dto.cost;
  const bagType = payableData.payable.bagType;
  const {
    bag,
    uniqueTrackingId
  } = userContext || {};
  const relatedAddonProductId = payableData.payable.addonProductId;
  const items = payableData.payable.bets.map(bet => {
    var _drawInfo$draws, _drawInfo$jackpots;
    if (addonProducts.includes(bet.productId)) {
      return new TransactionItem(addonProductId[bet.productId], addonProductName[bet.productId], bet.price, 1);
    }
    const extraTracking = {};
    const extraInfoKey = "".concat(bet.productId, "_").concat(bet.drawNumber);
    if (drawInfo !== null && drawInfo !== void 0 && (_drawInfo$draws = drawInfo.draws) !== null && _drawInfo$draws !== void 0 && _drawInfo$draws[extraInfoKey]) {
      const extra = drawInfo.draws[extraInfoKey];
      let fund = {
        extrapengar: '0.00',
        rullpott: '0.00'
      };
      if (extra.fund) {
        fund = {
          extrapengar: extra.fund.extraMoney,
          rullpott: extra.fund.rolloverIn
        };
      }
      Object.assign(extraTracking, {
        weekRound: extra.regOpenTime ? dateFns.getISOWeek(dateFns.parseISO(extra.regOpenTime)) : 0
      }, fund);
    }
    if (drawInfo !== null && drawInfo !== void 0 && (_drawInfo$jackpots = drawInfo.jackpots) !== null && _drawInfo$jackpots !== void 0 && _drawInfo$jackpots[bet.productId]) {
      const jackpot = drawInfo.jackpots[bet.productId];
      Object.assign(extraTracking, {
        jackpot: jackpot.length ? jackpot.join('|') : '0.00'
      });
    }
    const quickpick = bet.quickPickValue;
    if (quickpick) {
      Object.assign(extraTracking, {
        quickpick
      });
    }
    let compType = 'None';
    if (payableData.payable.isCompetition()) {
      const competition = compHandler.getActiveCompetition(bet.productId);
      compType = (competition === null || competition === void 0 ? void 0 : competition.getCompType()) || compType;
    }
    const productName = relatedAddonProductId ? relatedAddonProductName[relatedAddonProductId] : bet.productName;
    return new TransactionItem(bet.itsProductId, productName, bet.price, 1, 
    _objectSpread(_objectSpread({}, extraTracking), {}, {
      rounds: bet.numberOfDraws,
      system: bet.getSystemLabel(),
      competition: compType,
      draw: bet.drawNumber,
      bag,
      bagType,
      uniqueTrackingId,
      shares: Boolean(typeOfShares),
      typeOfShares
    }));
  });
  const product = relatedAddonProductId ? relatedAddonProductName[relatedAddonProductId] : productBet.productName;
  const transaction = new Transaction(payableData.dto.transactionId, totalPrice, items, 0, 0, undefined, product);
  return transaction;
};

const extractProductInfoFormWagers = payableItemsData => {
  const productInfo = new Map();
  for (const {
    payable: wager
  } of payableItemsData) {
    for (const {
      productId,
      drawNumber
    } of wager.bets) {
      if (ADDITIONAL_PRODUCTS.includes(productId)) {
        productInfo.set("".concat(productId, "_").concat(drawNumber), {
          productId,
          drawNumber
        });
      }
    }
  }
  return Array.from(productInfo.values());
};

const getMarketplaceInfo = payableItemsData => {
  let typeOfShare;
  for (const {
    payable: wager
  } of payableItemsData) {
    if (wager.role) {
      typeOfShare = convertRoleToTypeOfShare[wager.role];
    }
  }
  return typeOfShare;
};

const trackWagers = async (payableItemsData, userContext) => {
  const uniqueProducts = extractProductInfoFormWagers(payableItemsData);
  const marketplaceInfo = getMarketplaceInfo(payableItemsData);
  const drawInfo = await fetchDrawInfo(uniqueProducts);
  for (const payableData of payableItemsData) {
    const trackingWager = createTransaction(payableData, drawInfo, userContext, marketplaceInfo);
    analytics.trackTransaction(trackingWager);
  }
};
setGlobal('svs.components.payment.serviceWager.utils.trackWagers', trackWagers);

 })(window);