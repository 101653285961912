(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-wager/assets/services/post-marketplace-wager.js') >= 0) return;  svs.modules.push('/components/payment/service-wager/assets/services/post-marketplace-wager.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  RequestPaymentError
} = svs.components.payment.common.error;
const {
  jupiterFetch
} = svs.core;
const normalizeMarketplaceResponse = data => {
  const {
    serial,
    betAmount
  } = data.CreateWagerReply;
  return _objectSpread(_objectSpread({}, data), {}, {
    wagerClientSerial: serial,
    wagerAmounts: {
      betAmount,
      totalAmount: betAmount
    }
  });
};
const postMarketplaceWager = async wager => {
  try {
    const activatedDrawId = wager.activatedDrawId;
    const response = await jupiterFetch.fetch("/marketplace/1/activateddraws/".concat(activatedDrawId, "/wagers"), {
      method: 'POST',
      body: JSON.stringify(wager),
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    });
    const data = await response.json();
    if (!response.ok) {
      var _data$error, _data$error2;
      throw new RequestPaymentError({
        clientCode: RequestPaymentError.ClientErrorCodes.PAYMENT_REQUEST_FAILURE,
        code: (_data$error = data.error) === null || _data$error === void 0 ? void 0 : _data$error.code,
        extraData: {
          message: (_data$error2 = data.error) === null || _data$error2 === void 0 ? void 0 : _data$error2.message
        }
      });
    }
    const normalizedData = normalizeMarketplaceResponse(data);
    return normalizedData;
  } catch (error) {
    switch (error === null || error === void 0 ? void 0 : error.name) {
      case 'TypeError':
        throw new RequestPaymentError({
          clientCode: RequestPaymentError.ClientErrorCodes.PAYMENT_FETCH_FAILURE,
          code: error === null || error === void 0 ? void 0 : error.code,
          extraData: {
            message: error === null || error === void 0 ? void 0 : error.message
          }
        });
      default:
        throw error;
    }
  }
};
setGlobal('svs.components.payment.serviceWager.services.postMarketplaceWager', postMarketplaceWager);

 })(window);